import React, {useEffect, useMemo, useState} from 'react';
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {ClickableDiv, FlexColumnSection, FlexRowSection, SmallHeader} from "../../../styles/globalStyles";
import dayjs from "dayjs";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {useSelector} from "react-redux";
import {light, solid, regular} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "i18next";
import {Tooltip} from "antd";
import CustomDatePicker from "../../../components/UI/CustomDatePicker";
import FiltersHandler from "../../../components/FiltersHandler";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {getFiltersConfig} from "@arboxappv4/shared/src/helpers/filters";
import {isRTL} from "../../../helpers/i18n";
import styled from "styled-components";
import {bookingTypes, membershipTypes} from "@arboxappv4/shared/src/helpers/constants";

const GroupSessionsHeader = (props) => {
    const { to, from, onDateChange, sessions, onFiltered, selectedLocation } = props;
    const language = useSelector(state => state.site.language)
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const [filtersConfig, setFiltersConfig] = useState(null);
    const {isMobile} = useScreenSize()

    const sessionsFlat = useMemo(() => sessions ? Object.values(sessions).reduce((acc, dateSessions) => [...acc, ...dateSessions],[]) : null, [sessions]);

    const hasTrial = useMemo(() => {
        if(sessionsFlat && !loggedIn) {
            const trialSessions = sessionsFlat.filter(session => {
                const membershipType = session.series?.membership_types.find(item => item.type === membershipTypes.TRIAL)
                return !!membershipType
            })
            if(trialSessions.length > 0) {
                return trialSessions.filter(session => session.booking_option !== bookingTypes.PAST && session.free > 0).length > 0
            }
        }
        return false
    }, [sessionsFlat, loggedIn]);

    const boxCategories = useMemo(() => {
        if(sessionsFlat) {
            const optionsObj = sessionsFlat.reduce((acc, session) => ({...acc, [`type-${session.box_category_fk}`]: session.box_categories}), {})
            return sortAlphabetically(Object.values(optionsObj), 'name')
        }
    }, [sessionsFlat]);

    const coaches = useMemo(() => {
        if(sessionsFlat) {
            const optionsObj = sessionsFlat.reduce((acc, session) => {
                let tempRes = acc
                if (session.coach) {
                    tempRes = {...tempRes, [`coach-${session.coach_fk}`]: session.coach}
                }
                if (session.second_coach) {
                    tempRes = {...tempRes, [`coach-${session.second_coach_fk}`]: session.second_coach}
                }
                return tempRes
            }, {})
            return sortAlphabetically(Object.values(optionsObj), 'full_name')
        }
    }, [sessionsFlat]);

    const boxCategoriesGroups = useMemo(() => {
        if(sessionsFlat) {
            const optionsObj = sessionsFlat.reduce((acc, session) => {
                if(session.box_categories.box_categories_groups.length > 0) {
                    const groupsObj = session.box_categories.box_categories_groups.reduce((acc, group) => ({...acc, [`group-${group.id}`]: group}),{})
                    return {...acc, ...groupsObj}
                }
                return acc
            }, {})
            return sortAlphabetically(Object.values(optionsObj), 'name')
        }
    }, [sessionsFlat]);

    const spaces = useMemo(() => {
        if(sessionsFlat && filtersConfig) {
            const optionsObj = sessionsFlat.reduce((acc, session) => (session.spaces_id ? {...acc, [`space-${session.spaces_id}`]: session.spaces} : acc), {})
            return sortAlphabetically(Object.values(optionsObj), 'name')
        }
    }, [sessionsFlat]);

    const customFields = useMemo(() => {
        if(sessionsFlat) {
            const customFieldsObj = sessionsFlat.reduce((acc, session) => {
                let cfArr = [];
                if(session.custom_field_value.length) {
                    cfArr.push(...session.custom_field_value.map(cfv => cfv.custom_field))
                }
                if(session.series && session.series.custom_field_value.length) {
                    cfArr.push(...session.series.custom_field_value.map(cfv => cfv.custom_field))
                }
                let cfObj = cfArr.reduce((acc2, cf) => ({...acc2, [`cf-${cf.id}`]: cf}), {})
                return {...acc, ...cfObj}
            }, {})
            return Object.values(customFieldsObj)
        }
    }, [sessionsFlat]);

    useEffect(() => {
        if(boxCategories && coaches && boxCategoriesGroups) {
            initFiltersConfig()
        }
    }, [boxCategories, coaches, boxCategoriesGroups, spaces, userBox, hasTrial, customFields]);

    const navigateDates = (isNext) => {
        let toNew = dayjs(to, DB_DATE_FORMAT)
        let fromNew = dayjs(from, DB_DATE_FORMAT)
        if(isMobile) {
            fromNew = isNext ? fromNew.add(1, 'day') : fromNew.subtract(1, 'day');
            toNew = fromNew
        } else {
            fromNew = isNext ? fromNew.add(7, 'day') : fromNew.subtract(7, 'day');
            toNew = isNext ? toNew.add(7, 'day') : toNew.subtract(7, 'day');
        }
        onDateChange({from: fromNew.format(DB_DATE_FORMAT), to: toNew.format(DB_DATE_FORMAT), locations_box_id: selectedLocation === 'all' ? null : selectedLocation})
    }

    const goToDate = (date) => {
        onDateChange({from: date, to: date, locations_box_id: selectedLocation === 'all' ? null : selectedLocation})
    }

    const initFiltersConfig = () => {
        const config = getFiltersConfig({
            boxCategories,
            coaches,
            locations: {rest: {outside: true}},
            vacancy: true,
            boxCategoriesGroups,
            spaces,
            trial: hasTrial,
            customFields
        }, selectedLocation, t)
        setFiltersConfig(config)
    }

    return (
        <FlexRowSection justify={'space-between'} flex={'unset'}>
            {isMobile ?
                <FlexColumnSection gap={'10px'}>
                    <FlexRowSection justify={'space-between'} flex={'unset'}>
                        <CustomDatePicker anchor={
                            <FlexRowSection gap={'8px'}>
                                {to && <SmallHeader>{dayjs(to, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(`ddd, ${language?.code === 'he' ? 'D MMM' : 'MMM D'}`)}</SmallHeader>}
                                <FontAwesomeIcon icon={regular('chevron-down')} color={Colors.siteBlack} size={'xs'}/>
                            </FlexRowSection>
                        } onDateSelect={goToDate}/>
                        <FlexRowSection gap={'20px'} flex={'unset'}>
                            <FlexRowSection gap={'25px'}>
                                <div onClick={() => navigateDates(false)}><FontAwesomeIcon icon={light('angle-left')} color={Colors.siteBlack} size={'lg'} flip={isRTL() ? 'horizontal' : 'vertical'}/></div>
                                <div onClick={() => navigateDates(true)}><FontAwesomeIcon icon={light('angle-right')} color={Colors.siteBlack} size={'lg'} flip={isRTL() ? 'horizontal' : 'vertical'}/></div>
                            </FlexRowSection>
                        </FlexRowSection>
                    </FlexRowSection>
                    {filtersConfig && <FiltersHandler unfilteredData={sessions} onFiltered={onFiltered} initConfig={filtersConfig} pageName={'group'}/>}
                </FlexColumnSection>
                :
                <FlexRowSection gap={'20px'}>
                    {filtersConfig && <FiltersHandler unfilteredData={sessions} onFiltered={onFiltered} initConfig={filtersConfig} pageName={'group'}/>}
                    <FlexRowSection gap={'20px'} flex={'unset'}>
                        {to && from && <SmallHeader>{dayjs(from, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(language?.code === 'he' ? 'D MMMM' : 'MMMM D')} - {dayjs(to, DB_DATE_FORMAT).locale(language?.code ?? 'en').format(language?.code === 'he' ? 'D MMMM' : 'MMMM D')}</SmallHeader>}
                        <FlexRowSection gap={'20px'} flex={'unset'}>
                            <ArrowWrapper onClick={() => navigateDates(false)}><Tooltip title={t('prev-week')}><FontAwesomeIcon icon={solid('angle-left')} color={Colors.siteBlack} size={'sm'} flip={isRTL() ? 'horizontal' : 'vertical'}/></Tooltip></ArrowWrapper>
                            <ArrowWrapper onClick={() => navigateDates(true)}><Tooltip title={t('next-week')}><FontAwesomeIcon icon={solid('angle-right')} color={Colors.siteBlack} size={'sm'} flip={isRTL() ? 'horizontal' : 'vertical'}/></Tooltip></ArrowWrapper>
                        </FlexRowSection>
                    </FlexRowSection>
                </FlexRowSection>
            }
        </FlexRowSection>
    );
};

export default GroupSessionsHeader;

const ArrowWrapper = styled.div`
    cursor: pointer;
    border-radius: 5px;
    height: 26px;
    width: 26px;
    justify-content: center;
    display: flex;
    align-items: center;
    
    :hover {
        background: ${Colors.fullyBooked};
    }
`;
