import React, {lazy, useEffect, useMemo, useState} from 'react';
import {Btn, EmptyFlexDiv, FlexColumnSection, FlexDiv, FlexRowSection, SmallText} from "../../../styles/globalStyles";
import FormInput from "../../../components/UI/FormInput";
import {checkIfDateAfterToday} from "../../../helpers/functions";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import WhatsNextBox from "../../../components/WhatsNextBox";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {t} from "i18next";
import styled from "styled-components";
import {Colors} from "@arboxappv4/shared/src/styles/Colors";
import dayjs from "dayjs";
import CustomFieldSwitcher from "../../../components/UI/CustomFieldSwitcher";
import {useSelector} from "react-redux";
import CustomSelect from "../../../components/UI/CustomSelect";

const CustomDatePicker = lazy(() => import(/* webpackChunkName: "CustomDatePicker" */'../../../components/UI/CustomDatePicker'));

const ChildDetails = (props) => {
    const { handleSubmit, values, errors, setFieldValue, touched, validationSchema} = props;
    const [submitted, setSubmitted] = useState(false);
    const {isMobile} = useScreenSize()
    const box = useSelector(state => state.site.box)
    const customFieldsDataPerLocation = useMemo(() => box?.locations_box?.find(location => location.id === values.locations_box_fk)?.custom_field, [values.locations_box_fk,box]);

    useEffect(() => {
        const newFields = customFieldsDataPerLocation?.map(field => {
            if(values.child.customFields) {
                const old = values.child.customFields.find(oldField => oldField.id === field.id)
                if (old) {
                    return {...field, value: old.value}
                }
            }
            return field;
        })
        setTimeout(() => setFieldValue('child.customFields', newFields),10);
    }, [customFieldsDataPerLocation]);

    const getError = (propName) => {
        if(submitted && errors.child && errors.child[propName]) {
            return errors.child[propName]
        }
        return null
    }

    const submitStep = () => {
        setSubmitted(true)
        handleSubmit()
    }

    const inputLabel = (name, label) => {
        if(validationSchema && validationSchema.fields?.child?.fields) {
            const fields = validationSchema.fields.child.fields;
            if(fields[name] && !fields[name].spec.optional) {
                return `${label}*`
            }
        }
        return label
    }

    if(!values.child) return null

    return (
        <FlexRowSection justify={'space-between'} gap={'40px'} height={'100%'} align={'start'}>
            <FlexColumnExtended gap={'20px'} isMobile={isMobile} height={'100%'}>
                <FlexColumnSection gap={'24px'} height={isMobile ? `calc(100% - ${25+38}px)` : 'auto'} overflow={'auto'} flex={1}>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('first_name', t('first-name'))} value={values.child.first_name} error={getError('first_name')} onChangeCallback={(val) => setFieldValue('child.first_name', val)} autoComplete={'given-name'} id="firstName" flex={1}/>
                        <FormInput label={inputLabel('last_name', t('last-name'))} value={values.child.last_name} error={getError('last_name')} onChangeCallback={(val) => setFieldValue('child.last_name', val)} autoComplete={'family-name'} id="lastName" flex={1}/>
                    </FlexDiv>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('personal_id', t('personal-id'))} value={values.child.personal_id} error={getError('personal_id')} onChangeCallback={(val) => setFieldValue('child.personal_id', val)} type={'phone'} flex={1} inputMode={'tel'}/>
                        <FormInput label={inputLabel('phone', t('phone'))} value={values.child.phone} error={getError('phone')} onChangeCallback={(val) => setFieldValue('child.phone', val)} type={'phone'} autoComplete={'phone'} id={'phone'} flex={1} inputMode={'tel'}/>
                    </FlexDiv>
                    <FlexDiv dir={isMobile ? 'column' : 'row'} gap={'20px'}>
                        <FormInput label={inputLabel('birthday', t('birth-date'))} value={values.child.birthday} error={getError('birthday')} flex={1} showOptional={true}>
                            <CustomDatePicker onDateSelect={(val) => setFieldValue('child.birthday', val)}
                                              disabledDate={checkIfDateAfterToday}
                                              validRange={[dayjs('1920-01-01',DB_DATE_FORMAT), dayjs()]}
                            />
                        </FormInput>
                        <FormInput showOptional={true} label={inputLabel('gender', t('gender'))} value={values.child.gender} flex={1}>
                            <CustomSelect allowClear filterSort={(a,b) => a - b} onChange={(val) => setFieldValue('child.gender', val)} options={[{ label: t('male'), value: 'male' }, { label: t('female'), value: 'female' }]} defaultValue={values.child.gender} />
                        </FormInput>
                    </FlexDiv>
                    {values.child.customFields && <CustomFieldSwitcher data={values.child.customFields} setFieldValue={setFieldValue} touched={touched} errors={errors} isChild/> }
                </FlexColumnSection>
                <FlexRowSection height={'38px'} flex={'none'} justify={'end'}>
                    <Btn type={'primary'} onClick={submitStep} width={isMobile ? '100%' : null}>{t('continue')}</Btn>
                </FlexRowSection>
            </FlexColumnExtended>
            {!isMobile && <WhatsNextBox text={t('course-child-details-whats-next')}/>}
        </FlexRowSection>
    );
};

export default ChildDetails;

const FlexColumnExtended = styled(FlexColumnSection)`
    padding: ${({isMobile}) => isMobile ? '0 16px' : '20px'};
    background: ${({isMobile}) => isMobile ? 'none' : Colors.white};
    box-sizing: border-box;
    border-radius: 5px;
`;


